<template>
  <layout-base>
    <div class="grid p-5">
      <div class="col-6"><Chart type="line" :data="basicData" /></div>
      <div class="col-6">
        <Chart type="bar" :data="stackedData" :options="stackedOptions" />
      </div>
      <div class="col-6">
        <Chart type="bar" :data="stackedData" :options="stackedOptions" />
      </div>
      <div class="col-6">
        <Chart type="line" :data="basicData" />
      </div>
    </div>
  </layout-base>
</template>

<script>
import FilterCompany from "@/components/FilterCompany.vue";
import LoadingProgress from "@/components/UI/LoadingProgress";

export default {
  data() {
    return {
      basicData: {
        labels: [
          "Январь",
          "Февраль",
          "Март",
          "Апрель",
          "Май",
          "Июнь",
          "Июль",
          "Август",
          "Сентябрь",
          "Октябрь",
          "Ноябрь",
          "Декабрь",
        ],
        datasets: [
          {
            label: "Итоги в литрах",
            data: [65, 59, 80, 81, 56, 55, 40, 81, 56, 55, 40, 40],
            fill: false,
            borderColor: "#42A5F5",
            tension: 0.4,
          },
          {
            label: "Итоги в рублях",
            data: [28, 48, 40, 19, 86, 27, 90, 59, 80, 81, 56, 81],
            fill: false,
            borderColor: "#FFA726",
            tension: 0.4,
          },
        ],
      },
      stackedData: {
        labels: [
          "Январь",
          "Февраль",
          "Март",
          "Апрель",
          "Май",
          "Июнь",
          "Июль",
          "Август",
          "Сентябрь",
          "Октябрь",
          "Ноябрь",
          "Декабрь",
        ],
        datasets: [
          {
            type: "bar",
            label: "Dataset 1",
            backgroundColor: "#42A5F5",
            data: [28, 48, 40, 19, 86, 27, 90, 59, 80, 81, 56, 81],
          },
          {
            type: "bar",
            label: "Dataset 2",
            backgroundColor: "#66BB6A",
            data: [21, 84, 24, 75, 37, 65, 34, 27, 10, 10, 10, 10, 5, 2],
          },
          {
            type: "bar",
            label: "Dataset 3",
            backgroundColor: "#FFA726",
            data: [65, 59, 80, 81, 56, 55, 40, 81, 56, 55, 40, 40],
          },
        ],
      },
    };
  },
  components: {
    FilterCompany,
    LoadingProgress,
  },
  computed: {},
  methods: {},
  mounted() {},

  created() {
    document.title = "Статистика | " + window.appBaseTitle;
  },
};
</script>
    
    <style lang="scss" >
</style>
    
    
     